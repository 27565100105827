const financeDesktopText = {
    GOODS_REST: '存货',
    CASH_REST: '现金余额',
    CHECK_RECEIVE: '核销应收',
    ACTUAL_PAY: '应付'
}

const budgetsNo = {
  SALE:'XT000012',
  GMV:'XT000001',
  INCOME:'XT000002',
  PRODUCT_EDITORIAL_CONTRIBUTION:'XT000004',
  STORE_EDITORIAL_CONTRIBUTION:'XT000011',
  NET_PROFIT:'XT000006',

}

export default {
  financeDesktopText
}