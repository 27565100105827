<template>
    <CardComponent
        :title="title"
        :icon="icon"
        class="cash-medium-card"
    >
        <template v-slot:cardOptions>
            <slot name="cashOption"></slot>
        </template>
        <template v-slot:cardHeader v-if="handleIcon">
            <i
                element-loading-spinner="el-icon-loading"
                :class="handleIcon"
                @click="showDialog(title)"
            ></i>
        </template>
        <div class="cash-medium-main">
            <div
                class="cash-medium-main__top"
                v-if="sumData && sumData.length > 0"
            >
                <div v-for="sumItem in sumData" :key="sumItem.title">
                    <p class="title">{{ sumItem.title }}</p>
                    <p class="value">
                        <!-- <span>￥</span> -->
                        {{ sumItem.value }}
                    </p>
                </div>
            </div>
            <div class="cash-medium-main__top" v-if="showDate">
                <el-date-picker
                    v-model="date"
                    class="date-picker"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :clearable="false"
                    :editable="false"
                    @change="dateChange"
                >
                </el-date-picker>
            </div>
            <div
                class="cash-medium-main__body"
                v-if="datas && datas.length > 0"
            >
                <div
                    :class="{
                        'cash-item': true,
                        columns: true,
                        item2: title === contant.financeDesktopText.CASH_REST
                    }"
                >
                    <span v-for="colmun in columns" :key="colmun">{{
                        colmun
                    }}</span>
                </div>
                <div
                    :class="{
                        scroll: true,
                        'scroll-item2':
                            title === contant.financeDesktopText.CASH_REST
                    }"
                >
                    <el-scrollbar style="height:100%">
                        <div
                            :class="{
                                'cash-item': true,
                                'cash-data': true,
                                'item-data2':
                                    title ===
                                    contant.financeDesktopText.CASH_REST
                            }"
                            v-for="data in datas"
                            :key="data.id"
                        >
                            <span v-if="title !== '核销应收'">{{
                                data.title
                            }}</span>
                            <span v-else>
                                <el-link
                                    @click="showEdit(data)"
                                    type="primary"
                                    >{{ data.title }}</el-link
                                >
                            </span>
                            <span>{{ data.value1 }}</span>
                            <span v-if="data.hasOwnProperty('value2')" :class="{'markRed': data.delay}">{{ data.value2 }}</span>
                        </div>
                    </el-scrollbar>
                    <!-- <el-table height="2.5rem" :data="datas" align="center">
                        <el-table-column prop="title" show-overflow-tooltip label="migncheng">
                        </el-table-column>
                        <el-table-column prop="value1" show-overflow-tooltip label="migncheng">
                        </el-table-column>
                        <el-table-column prop="value2" show-overflow-tooltip label="migncheng">
                        </el-table-column>
                    </el-table> -->
                </div>
                <div
                    v-if="title === contant.financeDesktopText.CASH_REST"
                    class="cash-medium-total"
                >
                    <span>合计</span>
                    <span>￥{{ totalAmount }}</span>
                </div>
            </div>
            <Empty :showImg="false" v-else />
        </div>
        <cashRest :parenterId="parenterId" v-on="$listeners" ref="cashRest" />
        <addCheckReceive v-bind="$attrs" v-on="$listeners" ref="addCheckReceive" />
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import cashRest from '../components/restCashDialog'
import addCheckReceive from '../components/addCheckReceive'
import contant from './contant'
import tradeSvc from 'services/trade'
import Empty from '@/components/empty'
import _ from 'lodash'
import financeSvc from 'services/finance'
export default {
    name: 'CashMediumCard',
    components: { CardComponent, cashRest, addCheckReceive, Empty },
    props: {
        title: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'iconfont icon-caiwuyunying'
        },
        handleIcon: {
            type: String,
            default: ''
        },
        totalAmount: {
            type: String,
            default: ''
        },
        columns: {
            type: Array,
            default: null
        },
        sumData: {
            type: Array,
            default: null
        },
        datas: {
            type: Array,
            default: null
        },
        chooseDate: {
            type: Boolean,
            default: false
        },
        showDate: {
            type: Boolean,
            default: false
        },
        parenterId: {
          type: Number,
          default: null
        }
    },
    data() {
        return {
            date: null,
            contant
        }
    },
    // created() {
    //   this.date = this.$moment().format('YYYY-MM-DD')
    // },
    methods: {
        dateChange() {
            this.$emit('get-cash-reset', this.date)
        },
        async showDialog(title) {
            console.log(contant.financeDesktopText)
            console.log(title)
            console.log(this.parenterId);
            if (title === contant.financeDesktopText.CASH_REST) {
                try {
                    // await financeSvc.getCashByDate('2021-06-01')
                    this.$set(this.$refs.cashRest,'dateTime',new Date())
                    this.$refs.cashRest.visible = true
                } catch (error) {
                    console.log(error)
                }
            }
            if (title === contant.financeDesktopText.CHECK_RECEIVE) {
                try {
                    this.$refs.addCheckReceive.isEdit = false
                    this.$refs.addCheckReceive.visible = true
                } catch (error) {
                    console.log(error)
                }
            }
        },
        // async queryCheckReceiveBillById(){
        //   try {
        //     await queryCheckReceiveBillById
        //   } catch (error) {

        //   }
        // }
        async showEdit(item) {
            console.log(item)
            const { data = {} } = await financeSvc.queryCheckReceiveBillById(
                item.id
            )
            let _ref = this.$refs.addCheckReceive
            data.dateRange = [data.startTime, data.endTime]
            _ref.isEdit = true
            _ref.form = _.cloneDeep(data)
            _ref.visible = true
        }
    }
}
</script>
<style lang="scss" scoped>
.cash-medium-card {
    grid-row-end: span 2;
    ::v-deep .main {
        padding: 0;
        .com-card-header {
            padding: 0.2rem;
        }
        .com-card-header .left {
            display: flex;
        }
        .com-card-header .right {
            i {
                font-size: 0.28rem;
            }
        }
        .com-card-header .title {
            font-size: 0.24rem;
        }
    }
}
.cash-medium {
    &-main {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    &-main__top {
        display: flex;
        padding: 0 0.2rem;
        > div {
            flex: 1;
        }
        > div + div {
            margin-left: 0.15rem;
        }
        p {
            font-weight: 500;
        }
        p.title {
            font-size: 0.18rem;
            color: #909399;
            // line-height: 0.26rem;
        }
        p.value {
            span {
                font-size: 0.18rem;
            }
            display: flex;
            font-size: 0.24rem;
            color: #54636f;
            line-height: 0.44rem;
        }
        ::v-deep .date-picker {
            width: 1.6rem;
            cursor: pointer;
            .el-icon-date,
            input {
                font-size: 0.2rem;
            }
            .el-icon-date {
                line-height: 42px;
            }
            .el-input__inner {
                cursor: pointer;
                border: none;
                background: transparent;
                padding-left: 0.35rem;
                padding-right: 0;
                color: #606266;
            }
        }
    }
    &-main__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        .scroll {
            // flex: 1;
            height: 2.2rem;
            // padding-bottom: .2rem;
            box-sizing: border-box;
        }
        .scroll-item2.scroll {
            // height: 2rem;
        }
        .cash-item.columns {
            span {
                color: #a7b6c3;
            }
        }
        .cash-item {
            display: flex;
            padding: 0.09rem 0.2rem;
            span {
                font-size: 0.14rem;
                color: #606266;
            }
            > span:nth-child(1) {
                width: 1.04rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            > span:nth-child(2),
            > span:nth-child(3) {
                flex: 1;
                text-align: right;
            }
            span.markRed {
              color: #DF5A68;
            }
        }
        .cash-item.item2 {
            > span {
                flex: 1;
            }
        }
        .cash-item.item2,
        .cash-item.item-data2 {
            > span {
                flex: 1;
            }
            > span:nth-child(2) {
                text-align: right;
            }
        }
    }
    &-total {
        padding: 0 0.2rem;
        line-height: 0.24rem;
        > span {
            display: inline-block;
            width: 50%;
            font-size: 0.14rem;
            color: #606266;
            font-weight: 500;
        }
        > span:nth-child(2) {
            text-align: right;
        }
    }
}
</style>
